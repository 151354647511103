import React, { useState, useEffect } from 'react';
import KeplerGl from "kepler.gl";
import { addDataToMap, addFilter, setFilter, removeFilter } from "kepler.gl/actions";
import axios from 'axios';
import { useDispatch, connect } from "react-redux";
import PropTypes from 'prop-types';

var map;
var apiFetchData;
const Map = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const onLoadMap = () => {
      map.on('load', function () {
          // addFirstJson();
          dispatch(addFilter('covid19'));
          dispatch(setFilter(0, "name", "day"));

          var cJRVaE = document.getElementsByClassName('cJRVaE')[0];
          var btn = document.createElement("BUTTON");
          btn.innerHTML = "<strong>x</strong>";
          btn.className = "close-button";
          btn.addEventListener("click", function() {
            dispatch(removeFilter(0));
          });
          cJRVaE.appendChild(btn);
      });
    }

    const getData = async () => {
      const resp = await axios.get("https://gist.githubusercontent.com/leighhalliday/a994915d8050e90d413515e97babd3b3/raw/a3eaaadcc784168e3845a98931780bd60afb362f/covid19.json");
      let responseData = resp.data;
      apiFetchData = responseData;
      setData(responseData);
    }

    useEffect(() => {
      getData();
    }, []);
  
    useEffect(() => {
      if (data) {
        dispatch(
          addDataToMap({
            datasets: {
              info: {
                label: "COVID-19",
                id: "covid19"
              }, 
              data: data
            },
            option: {
              centerMap: true,
              readOnly: false,
            },
            color: '#ffffff',
            config: {
              mapStyle: {styleType: 'dark'}
            }
          })
        );
      }
    }, [dispatch, data]);
  
    const onChangeRange = (e) => {
      const { value } = e.target;
      let updateStateData = apiFetchData.rows.filter(item => item[5] > value);
      setData({
        ...data, rows: updateStateData
      });
    }
    return (
      <>
      <KeplerGl
        id="covid"
        mapboxApiAccessToken={'pk.eyJ1IjoiYXRvbWljZ3JvdXAiLCJhIjoiY2tsa21xN3ZwMDRmeDJ2bjR3NjloaDQxZCJ9.0pmL16fQeN4HGAUUheY91g'}
        width={window.innerWidth}
        height={window.innerHeight}
        getMapboxRef={ (mapRef) => {
          if(map === undefined)
          map = mapRef.getMap();
          onLoadMap();
        }}
      />
      <div id="range">
        <label>
          <input type="range" id="range-slider" className="slider" name="vol" min={0} step={1} max={1000} onChange={onChangeRange} />
        </label>
      </div>
      </>
    );
}

// Map.propTypes = {
//   setAlert:PropTypes.func.isRequired,
//   register:PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
  keplerGl: state.keplerGl
});

export default connect(mapStateToProps, { 
  // setAlert, register
 })(Map);